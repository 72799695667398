import React from 'react';

import { useTashkeel } from '@hooks/tashkeelContext';
import useTranslation from '@hooks/useTranslation';
import { Stack, Switch, Typography, styled } from '@mui/material';

import { colors } from 'theme';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: colors.secondary,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

function Tashkeel() {
    const { t } = useTranslation('library');
    const { tashkeelEnabled, toggleTashkeel } = useTashkeel();

    return (
        <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            onClick={() => toggleTashkeel()}
            sx={{ cursor: 'pointer' }}
        >
            <Typography>{t('tashkeel_nas')}</Typography>
            <AntSwitch
                checked={tashkeelEnabled}
                inputProps={{ 'aria-label': 'ant design' }}
            />
        </Stack>
    );
}

export default Tashkeel;
