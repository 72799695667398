import React from 'react';

import { FontSizes, useFontSize } from '@hooks/fontSizeContext';
import { fontSizeValues } from '@hooks/fontSizeContext';
import useTranslation from '@hooks/useTranslation';
import { Box, Stack, Typography } from '@mui/material';

import SettingsContainer from './SettingsContainer';
import { colors } from 'theme';

const ContentBox = ({
    size,
    title,
    active,
    onClick,
}: {
    size: string;
    title: string;
    active: boolean;
    onClick: () => void;
}) => {
    return (
        <Box
            sx={{
                border: active
                    ? `1px solid ${colors.secondary}`
                    : '0.5px solid rgba(183, 164, 164, 1)',
                borderRadius: '5px',
                width: '50%',
                height: '56px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            <Typography fontSize={size}>{title}</Typography>
        </Box>
    );
};

function FontSize() {
    const { t } = useTranslation('library');
    const { fontSize, setFontSize } = useFontSize();

    const handleFontSizeChange = (size: FontSizes) => {
        setFontSize(fontSizeValues[size]);
    };

    return (
        <Box>
            <SettingsContainer title={`${t('font_size')}`}>
                <Stack gap={'16px'}>
                    <Stack direction={'row'} gap={'16px'}>
                        <ContentBox
                            title={`${t('small_font')}`}
                            size={fontSizeValues[FontSizes.Small].title}
                            active={
                                fontSize.title ===
                                fontSizeValues[FontSizes.Small].title
                            }
                            onClick={() =>
                                handleFontSizeChange(FontSizes.Small)
                            }
                        />
                        <ContentBox
                            title={`${t('normal_font')}`}
                            size={fontSizeValues[FontSizes.Normal].title}
                            active={
                                fontSize.title ===
                                fontSizeValues[FontSizes.Normal].title
                            }
                            onClick={() =>
                                handleFontSizeChange(FontSizes.Normal)
                            }
                        />
                    </Stack>
                    <Stack direction={'row'} gap={'16px'}>
                        <ContentBox
                            title={`${t('large_font')}`}
                            size={fontSizeValues[FontSizes.Large].title}
                            active={
                                fontSize.title ===
                                fontSizeValues[FontSizes.Large].title
                            }
                            onClick={() =>
                                handleFontSizeChange(FontSizes.Large)
                            }
                        />
                        <ContentBox
                            title={`${t('verylarge_font')}`}
                            size={fontSizeValues[FontSizes.VeryLarge].title}
                            active={
                                fontSize.title ===
                                fontSizeValues[FontSizes.VeryLarge].title
                            }
                            onClick={() =>
                                handleFontSizeChange(FontSizes.VeryLarge)
                            }
                        />
                    </Stack>
                </Stack>
            </SettingsContainer>
        </Box>
    );
}

export default FontSize;
