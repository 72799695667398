import React, { ReactNode } from 'react';

import { Box, Typography } from '@mui/material';

function SettingsContainer({
    title,
    children,
}: {
    title: string;
    children: ReactNode;
}) {
    return (
        <Box
            sx={{
                border: '0.5px solid rgba(183, 164, 164, 1)',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                borderRadius: '5px',
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'rgba(246, 246, 246, 1)',
                    p: '16px',
                    borderTopRightRadius: '5px',
                    borderTopLeftRadius: '5px',
                    borderBottom: '0.5px solid rgba(183, 164, 164, 1)',
                }}
            >
                <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>
                    {title}
                </Typography>
            </Box>
            <Box sx={{ p: '20px' }}>{children}</Box>
        </Box>
    );
}

export default SettingsContainer;
