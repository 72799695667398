import { type ReactNode, useState } from 'react';

import SettingsButton from '@components/settings/SettingsButton';
import SwipeableSettingsDrawer from '@components/settings/SwipeableSettingsDrawer';

import { Footer, NavBar } from '@layout';
import { Box, useMediaQuery, useTheme } from '@mui/material';

const Page = ({
    children,
    headerIcons,
    hasSelectedFilters,
    onLogoClick,
}: {
    children: ReactNode;
    headerIcons?: ReactNode;
    hasSelectedFilters?: boolean;
    onLogoClick?: () => void;
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [openSettings, setOpenSettings] = useState(false);

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    minHeight: '100vh',
                    // to avoid the bottom nav bar and not have the last item obsecured by it
                    pb: '68px',
                }}
            >
                {isMobile ? (
                    !hasSelectedFilters && (
                        <NavBar
                            headerIcons={headerIcons}
                            onLogoClick={onLogoClick}
                        />
                    )
                ) : (
                    <NavBar headerIcons={headerIcons} />
                )}
                <Box
                    sx={{
                        height: {
                            xs: hasSelectedFilters ? '120px' : '80px',
                            sm: '60px',
                        },
                    }}
                ></Box>
                <Box
                    sx={{
                        width: '92%',
                        margin: '0 auto',
                        flex: 1,
                    }}
                >
                    {children}
                </Box>
                {/* settings drawer for desktop */}
                {!isMobile && (
                    <>
                        <SettingsButton setOpen={setOpenSettings} />
                        <SwipeableSettingsDrawer
                            open={openSettings}
                            setOpen={setOpenSettings}
                        />
                    </>
                )}
            </Box>
            {/* no footer on mobile to not conflict with bottom navbar */}
            {isMobile ? null : <Footer />}
        </Box>
    );
};

export default Page;
