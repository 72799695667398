import {
    type Dispatch,
    type ReactNode,
    type SetStateAction,
    useRef,
    useState,
} from 'react';

import { FullScreenHandle } from 'react-full-screen';

import ResultStats from '@components/results/elements/ResultStats';

import useTranslation from '@hooks/useTranslation';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {
    Box,
    Divider,
    Grid,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { GridFilterListIcon } from '@mui/x-data-grid';

import { FiltersDrawer } from './FiltersDrawer';
import { colors } from 'theme';

interface Props {
    filters: (setDrawerFiltersOpen: (open: boolean) => void) => ReactNode;
    collapsed?: boolean;
    showCollapsedBtn?: boolean;
    showFiltersMobileMainSearch?: boolean;
    hasSelectedFilters?: boolean;
    setCollapsed?: (x: boolean) => void;
    setShowFiltersMobileMainSearch?: Dispatch<SetStateAction<boolean>>;
    results: ((handleFilter: () => void) => ReactNode) | ReactNode;
    resultStatsMessage: ReactNode;
    fullScreenHandle?: FullScreenHandle;
}

const ReactiveSearchLayout = ({
    filters,
    results,
    collapsed,
    setCollapsed,
    hasSelectedFilters,
    setShowFiltersMobileMainSearch,
    showFiltersMobileMainSearch = false,
    showCollapsedBtn = true,
    resultStatsMessage,
    fullScreenHandle,
}: Props) => {
    const { t } = useTranslation('library');
    const [drawerFiltersOpen, setDrawerFiltersOpen] = useState(false);

    const [justClosedFullScreen, setJustClosedFullScreen] = useState(false);

    const mdFilters = collapsed ? 0 : 3;
    const mdBody = 12 - mdFilters;
    let tasnifBtn = <Box></Box>;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const filtersOpen = isMobile
        ? showFiltersMobileMainSearch || drawerFiltersOpen
        : !collapsed;
    const filtersOpenedAtLeastOnce = useRef(false);

    if (filtersOpen || hasSelectedFilters) {
        // user may be navigating to a page / link where the filters were already applied
        // so they're applied without him ever opening the filters
        filtersOpenedAtLeastOnce.current = true;
    }

    if (showCollapsedBtn) {
        tasnifBtn = (
            <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'flex-start'}
                width="100%"
                gap={1}
                sx={{
                    mb: collapsed ? 0 : 2,
                }}
            >
                {!collapsed ? (
                    <>
                        <KeyboardDoubleArrowRightIcon
                            onClick={() => {
                                if (setCollapsed) {
                                    setCollapsed(!collapsed);
                                }
                            }}
                            color="primary"
                            sx={{
                                backgroundColor: 'transparent',
                                mr: 1,
                                ':hover': {
                                    cursor: 'pointer',
                                },
                            }}
                        />
                        <Typography variant="body1" color="primary">
                            {t('tasnif')}
                        </Typography>
                    </>
                ) : (
                    <IconButton
                        onClick={() => {
                            if (setCollapsed) {
                                setCollapsed(false);
                            }
                        }}
                        sx={{
                            backgroundColor: colors.hover,
                            borderRadius: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            mb: hasSelectedFilters ? 1 : -2,
                        }}
                    >
                        <GridFilterListIcon color="primary" />
                        {hasSelectedFilters && (
                            <span
                                style={{
                                    content: '""',
                                    position: 'absolute',
                                    top: '25%',
                                    right: '8%',
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '50%',
                                    backgroundColor: colors.tertiary, // Customize the point color
                                }}
                            ></span>
                        )}
                        <Typography color="primary">{t('tasnif')}</Typography>
                    </IconButton>
                )}
            </Box>
        );
    }
    return (
        <>
            <Box
                sx={{
                    mt: { xs: 0, md: 2 },
                }}
            >
                {/* Header */}

                {/* Reactive Search */}

                {/* Main search */}
                <Grid container>
                    {/* Filters */}
                    <Grid
                        item
                        xs={0}
                        md={mdFilters}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                        pr={2}
                    >
                        {/* Desktop Filters */}
                        {tasnifBtn}
                        <div style={{ display: collapsed ? 'none' : 'block' }}>
                            {filtersOpenedAtLeastOnce.current
                                ? filters(
                                      setShowFiltersMobileMainSearch ??
                                          setDrawerFiltersOpen,
                                  )
                                : null}
                        </div>
                    </Grid>

                    <Grid item xs={12} md={mdBody}>
                        <Box
                            display="flex"
                            width="100%"
                            justifyContent="stretch"
                        >
                            <Box
                                sx={{
                                    display: {
                                        xs: 'none',
                                        md: collapsed ? 'none' : 'block',
                                    },
                                }}
                            >
                                <Divider orientation="vertical" />
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                width="100%"
                                sx={{ pl: collapsed ? { md: 0 } : { md: 2 } }}
                            >
                                {/* List */}
                                {typeof results === 'function'
                                    ? results(() => {
                                          setDrawerFiltersOpen(true);
                                          if (fullScreenHandle?.active) {
                                              fullScreenHandle.exit();
                                              setJustClosedFullScreen(true);
                                          }
                                      })
                                    : results}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                {/* Mobile Drawer */}
                <FiltersDrawer
                    open={drawerFiltersOpen || showFiltersMobileMainSearch}
                    onClose={() => {
                        setDrawerFiltersOpen(false);
                        if (setShowFiltersMobileMainSearch)
                            setShowFiltersMobileMainSearch(false);
                        // when exiting the drawer, open full screen if it was open before
                        if (justClosedFullScreen) {
                            fullScreenHandle?.enter();
                            setJustClosedFullScreen(false);
                        }
                    }}
                >
                    <ResultStats message={resultStatsMessage} />
                    {filtersOpenedAtLeastOnce.current
                        ? filters(
                              setShowFiltersMobileMainSearch ??
                                  setDrawerFiltersOpen,
                          )
                        : null}
                </FiltersDrawer>
            </Box>
        </>
    );
};

export default ReactiveSearchLayout;
