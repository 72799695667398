import { type ReactElement, type ReactNode } from 'react';

import Image from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { navbarItems } from '@config/navbar';
import useTranslation from '@hooks/useTranslation';
import {
    Slide,
    useMediaQuery,
    useScrollTrigger,
    useTheme,
} from '@mui/material';
import { AppBar, Box, Button, Toolbar } from '@mui/material';

import { colors } from 'theme';

interface PropsNavbar {
    headerIcons?: ReactNode;
    onLogoClick?: () => void;
}

interface Props {
    window?: () => Window;
    children: ReactElement;
}
function HideOnScroll(props: Props) {
    const { children, window } = props;

    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const NavBar = ({ headerIcons, onLogoClick }: PropsNavbar) => {
    const { t } = useTranslation('layout');
    const router = useRouter();

    const getBorderBottom = (pathname: string) => {
        return router.pathname === pathname
            ? `2px solid ${colors.secondary}`
            : '';
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const appBar = (
        <AppBar
            elevation={0}
            component="nav"
            sx={{
                backgroundColor: 'white',
                position: { xs: 'fixed', sm: 'static' },
                width: '100%',
                boxShadow: '0 5px 10px -2px rgba(0, 0, 0, 0.19)',
                py: '10px',
            }}
        >
            <Toolbar>
                <Box
                    width={!isMobile ? '100%' : 'fit-content'}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Image
                        alt="logo"
                        src={
                            isMobile
                                ? '/images/logo-vertical.png'
                                : '/images/logo-horizontal.png'
                        }
                        width={isMobile ? 32 : 128}
                        height={isMobile ? 38.5 : 46.5}
                        style={{
                            marginLeft: '8px',
                            marginBottom: '6px',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            if (onLogoClick) {
                                onLogoClick();
                            } else {
                                router.query = {};
                                router.push(router);
                            }
                        }}
                        priority
                    />
                    <Box
                        sx={{
                            display: {
                                xs: 'none',
                                sm: 'block',
                            },
                        }}
                    >
                        {navbarItems.map(({ label, path, pathname }) => (
                            <NextLink
                                href={path}
                                key={label}
                                className="navbar_link"
                            >
                                <Button
                                    key={label}
                                    color="primary"
                                    variant="text"
                                    sx={{
                                        px: 2,
                                        borderRadius: 0,
                                        borderBottom: getBorderBottom(pathname),
                                        '&:hover': {
                                            borderBottom: `2px solid ${colors.secondary}`,
                                        },
                                    }}
                                >
                                    {t(`${label}`)}
                                </Button>
                            </NextLink>
                        ))}
                    </Box>
                    <Box
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="row"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    ></Box>
                </Box>
                {isMobile && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'flex-end',
                        }}
                    >
                        {headerIcons}
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
    return (
        <Box width={'100%'}>
            {isMobile ? (
                <>
                    <HideOnScroll>{appBar}</HideOnScroll>
                </>
            ) : (
                appBar
            )}
        </Box>
    );
};

export default NavBar;
