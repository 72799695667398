import { Box, Divider, Grid, Skeleton } from '@mui/material';

const ReactiveSearchLayoutSkeleton = () => {
    return (
        <>
            <Box mt={2}>
                {/* Header */}

                {/* Reactive Search */}

                {/* Main search */}
                <Grid container>
                    {/* Filters */}
                    <Grid
                        item
                        xs={0}
                        md={3}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                    >
                        <Skeleton
                            variant="rounded"
                            width="100%"
                            height="100%"
                        />
                    </Grid>

                    <Grid item xs={12} md={9}>
                        <Box
                            display="flex"
                            width="100%"
                            justifyContent="stretch"
                        >
                            <Box
                                ml={2}
                                mr={2}
                                sx={{
                                    display: {
                                        xs: 'none',
                                        md: 'block',
                                    },
                                }}
                            >
                                <Divider orientation="vertical" />
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                width="100%"
                            >
                                <Skeleton
                                    variant="rounded"
                                    width="100%"
                                    height={800}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ReactiveSearchLayoutSkeleton;
