import Image from 'next/image';

import useTranslation from '@hooks/useTranslation';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Link } from '@mui/material';
import { Box } from '@mui/material';

const Footer = () => {
    const { t } = useTranslation('layout');

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: { xs: 'center', sm: 'space-between' },
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    marginTop: { xs: '30px', md: '60px' },
                    gap: '10px',
                    padding: '0 35px',
                    boxShadow: '0 -5px 10px -2px rgba(0, 0, 0, 0.19)',
                }}
            >
                <Link href="https://tamayyuzcenter.com/" target="_blank">
                    <Image
                        alt="logo"
                        src="/images/tamayuz1.svg"
                        width={50}
                        height={50}
                    />
                </Link>
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        fontSize: { xs: '11px', sm: '12px', md: '16px' },
                    }}
                >
                    {t('copyright')} {new Date().getFullYear()}
                </Box>
                <Box
                    sx={{
                        display: { xs: 'flex' },
                        justifyContent: { xs: 'center', sm: 'space-between' },
                        alignItems: { xs: 'center' },
                        flexDirection: { xs: 'column-reverse', sm: 'row' },
                    }}
                >
                    <Box
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            fontSize: { xs: '11px', sm: '12px', md: '16px' },
                            pb: { xs: '10px', sm: 0 },
                        }}
                    >
                        {t('copyright')} {new Date().getFullYear()}
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Link
                            href="https://www.facebook.com/profile.php?id=61563783331635"
                            target="_blank"
                        >
                            <FacebookIcon color="primary" />
                        </Link>
                        <Link href="https://x.com/alminasaai" target="_blank">
                            <XIcon color="primary" />
                        </Link>
                        <Link href="https://t.me/alminasaai" target="_blank">
                            <TelegramIcon color="primary" />
                        </Link>
                        <Link
                            href="https://www.youtube.com/channel/UCp1yewzLiNK2W_xM_lFK_DA"
                            target="_blank"
                        >
                            <YouTubeIcon color="primary" />
                        </Link>
                        <Link
                            href="https://www.instagram.com/alminasaai/"
                            target="_blank"
                        >
                            <InstagramIcon color="primary" />
                        </Link>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default Footer;
