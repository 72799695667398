import { type ReactNode } from 'react';

import { Box } from '@mui/material';

const ResultStats = (props: { message: ReactNode }) => {
    const renderedNode = props.message;
    return <Box>{renderedNode}</Box>;
};

export default ResultStats;
