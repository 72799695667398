import * as React from 'react';

import useTranslation from '@hooks/useTranslation';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import FontSize from './FontSize';
import Tashkeel from './Tashkeel';

interface SwipeableSettingsDrawerProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function SwipeableSettingsDrawer({
    open,
    setOpen,
}: SwipeableSettingsDrawerProps) {
    const { t } = useTranslation('layout');

    const toggleDrawer =
        (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setOpen(open);
        };

    const Drawer = () => (
        <Box sx={{ width: 400, p: 2 }} role="presentation">
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 4,
                }}
            >
                <Typography variant="h6">
                    {t('navbar_settings_references_with_l')}
                </Typography>
                <IconButton
                    sx={{ backgroundColor: '#eee' }}
                    onClick={() => setOpen(false)}
                >
                    <CloseIcon fontSize="small" color="secondary" />
                </IconButton>
            </Box>
            <Box mb={4}>
                <Tashkeel />
            </Box>
            <FontSize />
        </Box>
    );

    return (
        <div>
            <SwipeableDrawer
                anchor={'right'}
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                slotProps={{ backdrop: { invisible: true } }}
                disableScrollLock
            >
                <Drawer />
            </SwipeableDrawer>
        </div>
    );
}
