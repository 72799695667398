import React from 'react';

import { SettingsButtonIcon } from '@components/icons/Settings';

import { IconButton } from '@mui/material';

import { colors } from 'theme';

interface SettingsButtonProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SettingsButton = ({ setOpen }: SettingsButtonProps) => {
    return (
        <IconButton
            sx={{
                background: colors.secondary,
                p: 2,
                zIndex: 1000,
                position: 'fixed',
                borderRadius: 0,
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
                right: 0,
                top: '30%',
                transform: 'translateY(-50%)',
                '&:hover': {
                    background: colors.secondary,
                },
            }}
            onClick={() => setOpen(true)}
        >
            <SettingsButtonIcon />
        </IconButton>
    );
};

export default SettingsButton;
