import { type ReactNode } from 'react';

import useTranslation from '@hooks/useTranslation';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Drawer, IconButton, Typography } from '@mui/material';

interface IFiltersDrawerProps {
    window?: () => Window;
    open: boolean;
    onClose: () => void;
    children?: ReactNode;
}

const FiltersDrawer = ({
    window,
    open = false,
    onClose,
    children,
}: IFiltersDrawerProps) => {
    const { t } = useTranslation('library');
    return (
        <Drawer
            transitionDuration={50}
            container={
                window !== undefined ? () => window().document.body : undefined
            }
            variant="temporary"
            open={open}
            onClose={() => onClose()}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                zIndex: 9999999,
                display: { sm: 'block', md: 'none' },
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: '100%',
                },
            }}
        >
            <Box>
                <Box
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                    gap={2}
                    mb={1}
                    sx={{
                        position: 'fixed',
                        top: 0,
                        width: '100%',
                        backgroundColor: '#fff',
                        zIndex: 2,
                        padding: 2,
                        boxShadow: '0 5px 10px -2px rgba(0, 0, 0, 0.19)',
                    }}
                >
                    <IconButton onClick={() => onClose()}>
                        <ArrowForwardIcon color="secondary" />
                    </IconButton>
                    <Typography>{t('tasnif')}</Typography>
                </Box>
                <Box sx={{ padding: '10px', mt: 8 }}>{children}</Box>
            </Box>
        </Drawer>
    );
};

export default FiltersDrawer;
